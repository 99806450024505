import {css, html, LitElement} from "lit";

import {arrowBackIcon, redoIcon, feedbackIcon} from "../qset-icons.js";
import {customEvent} from "../utils/utils.js";

const styles = css`
  :host {
    display: block;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    padding: var(--action-button-padding) 0;
    height: var(--action-button-size);

    /* pass clicks through host element to the buttons */
    pointer-events: none;
  }

  :host([disabled]) {
    pointer-events: none;
  }

  :host([hidden]) {
    display: none;
  }

  :host([activeaction="none"][showbackbutton]) {
    display: block;
  }

  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 176px;
    height: var(--action-button-size);
    border-radius: 24px;
    color: var(--action-button-text-color);
    fill: var(--action-button-text-color);
    background-image: linear-gradient(
      360deg,
      var(--action-button-bottom-gradient-color) 0%,
      var(--action-button-top-gradient-color) 100%
    );
    border: 0;
    text-transform: uppercase;
    will-change: transform;
    cursor: pointer;
    user-select: none;
    animation: fadeIn 0.5s;
    margin: 0 auto;
    padding: 0 12px;
    box-sizing: border-box;
    pointer-events: all;
  }

  .button-label {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    /* For hide assistant bottom bar on Android Chrome */
    pointer-events: none;

    -webkit-touch-callout: none; /* Safari */
    -webkit-user-select: none; /* Chrome */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }

  .button-label.ok {
    font-size: 19px;
    line-height: 24px;
  }

  .backbutton,
  .feedback-button {
    display: none;
    width: var(--action-button-size);
    height: var(--action-button-size);
    position: absolute;
    right: 6px;
    top: 6px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #ddd;
    box-sizing: border-box;
    border-radius: 24px;
    fill: #444;
    opacity: 0;
    will-change: transform, opacity;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  }

  /* show buttons when side buttons are enabled */
  :host([canshowsidebuttons]) .backbutton,
  :host([canshowsidebuttons]) .feedback-button {
    display: block;
  }

  /* hide buttons when sticky */
  :host([issticky]) .backbutton,
  :host([issticky]) .feedback-button {
    display: none;
  }

  .feedback-button {
    cursor: pointer;
    animation: fadeInWithOpacity 0.5s forwards;
    pointer-events: all;
  }

  /* a little hack to keep the layout stable
   * keep the inactive item in the DOM, just hide and disable it */
  .backbutton {
    left: 6px;
    right: auto;
    pointer-events: none;
  }

  .backbutton[active] {
    cursor: pointer;
    pointer-events: all;
    animation: fadeInWithOpacity 0.5s forwards;
  }

  .backbutton svg {
    margin-top: calc((var(--action-button-size) - 24px) / 2);
    margin-left: calc((var(--action-button-size) - 24px) / 2);
  }

  .feedback-button svg {
    margin-top: calc((var(--action-button-size) - 24px) / 2 + 1px);
    margin-left: calc((var(--action-button-size) - 24px) / 2);
  }

  @keyframes fadeIn {
    from {
      transform: scale(0);
    }
    to {
      transform: none;
    }
  }

  @keyframes fadeInWithOpacity {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: none;
    }
  }

  /* from --card-width */
  @media screen and (min-width: 437px) {
    :host {
      position: relative;
    }

    .backbutton {
      left: calc(50% - var(--card-width) / 2);
    }

    .feedback-button {
      right: calc(50% - var(--card-width) / 2);
    }
  }

  /* Tablet wide */
  @media screen and (min-width: 600px) and (min-height: 480px) {
    :host {
      margin-bottom: 0;
    }

    .button {
      width: 200px;
      margin: 0 auto;
    }

    .button-label {
      font-size: 18px;
    }

    .feedback-button {
      display: none !important;
    }
  }

  /* Desktop 720p */
  @media screen and (min-width: 600px) and (max-height: 700px) {
    .button-label.ok {
      line-height: 21px;
    }
  }
`;

class StageButtons extends LitElement {
  constructor() {
    super();

    /**
     * Active action button type. Values are 'next', 'skip', 'none'.
     */
    this.activeAction = "none";

    /**
     * Label of the next button.
     */
    this.nextButtonLabel = "ok";

    /**
     * Show back button.
     */
    this.showBackButton = false;

    /**
     * If true, buttons are sticky, i.e. they are always visible.
     */
    this.isSticky = false;

    /**
     * If true, disable buttons.
     */
    this.disabled = false;

    /**
     * If true, show side buttons - feedback and back.
     */
    this.canShowSideButtons = false;
  }

  static get styles() {
    return styles;
  }

  static get properties() {
    return {
      activeAction: {
        type: String,
        reflect: true,
      },

      nextButtonLabel: {
        type: String,
      },

      showBackButton: {
        type: Boolean,
        reflect: true,
      },

      isSticky: {
        type: Boolean,
        reflect: true,
      },

      disabled: {
        type: Boolean,
        reflect: true,
      },

      canShowSideButtons: {
        type: Boolean,
        reflect: true,
      },
    };
  }

  render() {
    const renderNextButton = () => html`
      <div
        class="button"
        id="next"
        title="Potvrdit"
        @click="${this._onNextButtonClick}"
      >
        <div class="button-label ${this.nextButtonLabel}" id="buttonLabel">
          ${this.nextButtonLabel}
        </div>
      </div>
    `;

    const renderSkipButton = () => html`
      <div
        class="button"
        id="skip"
        title="Přeskočit otázku"
        @click="${this._onSkipButtonClick}"
      >
        ${redoIcon}
      </div>
    `;

    const renderBackButton = () => html`
      <div
        class="backbutton"
        @click="${this._onBackButtonClick}"
        ?active="${this.showBackButton}"
      >
        ${arrowBackIcon}
      </div>
    `;

    const renderFeedbackButton = () => html`
      <div
        class="feedback-button"
        title="Napište nám"
        @click="${this._onFeedbackButtonClick}"
      >
        ${feedbackIcon}
      </div>
    `;

    // a little hack to keep the layout stable
    // keep the inactive back button in the DOM, just hide and disable it
    return html`
      ${renderBackButton()}
      ${this.activeAction === "next" ? renderNextButton() : ""}
      ${this.activeAction === "skip" ? renderSkipButton() : ""}
      ${renderFeedbackButton()}
    `;
  }

  /**
   * Set focus to active button.
   */
  focusActiveButton() {
    this.shadowRoot.getElementById(this.activeAction)?.focus();
  }

  _onBackButtonClick() {
    this.dispatchEvent(new CustomEvent("back-button-click"));
  }

  _onNextButtonClick() {
    this.dispatchEvent(new CustomEvent("next-button-click"));
  }

  _onSkipButtonClick() {
    this.dispatchEvent(new CustomEvent("skip-button-click"));
  }

  _onFeedbackButtonClick() {
    this.dispatchEvent(customEvent("feedback-button-click", null, true));
  }
}

window.customElements.define("stage-buttons", StageButtons);
